import React, { Fragment, useState, useEffect, useMemo } from "react";
import { Button } from "reactstrap";
import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import {
  Col,
  Row,
  Card,
  CardBody,
  CustomInput,
  Input,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import ReactTable from "react-table";
import ApiCaller from "../../lib/ApiCaller";
import { toast } from "react-toastify";
import Popconfirm from "../../Components/PopConfirm";
import {
  faFileExcel,
  faEye,
  faFilter,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import PageTitle from "../../Layout/AppMain/PageTitle";
import Select from "react-select";
import { AvField, AvForm } from "availity-reactstrap-validation";
import * as configApi from '../../config/configApi.js';
const prefix = "ProcessManagement";
let style = {
  animationDelay: 1 * 100 + "ms",
  WebkitAnimationDelay: 1 * 100 + "ms",
};
const ColorTest = ["Black", "Blue", "Red", "Green", "Magenta"];

export default function ResultModal(modalTest, setModalTest, dataTest) {
  const columns = [
    {
      Header: "STT",
      id: "stt",
      accessor: "stt",
      sortable: false,
      filterable: false,
      Cell: (data) => {
        if (data.original.isParrent) {
          return data.original.stt;
        } else return "";
      },
      width: 50,
    },
    {
      Header: "Tên xét nghiệm",
      Cell: (row) => {
        if (row.original.isParrent == true) {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                fontWeight: "bold",
                fontSize: "15px",
              }}
            >
              {row.original.testname}
            </div>
          );
        } else {
          if (
            row.original.testcodeParent != null &&
            row.original.testcodeParent != ""
          ) {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                  paddingLeft: 25,
                }}
              >
                {row.original.testname}
              </div>
            );
          } else {
            return (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                {row.original.testname}
              </div>
            );
          }
        }
      },
      accessor: "testname",
      filterable: false,
      width: 350,
    },
    {
      Header: "Kết quả",
      Cell: (state) => (
        <div
          style={{
            fontWeight: "bold",
            color:
              state.row._original.color == null
                ? ""
                : ColorTest[state.row._original.color],
          }}
        >
          {console.log(state)}
          {state.row._original.result}
        </div>
      ),
      filterable: false,
      width: 120,
    },
    {
      Header: "Khoảng tham chiếu",
      accessor: "normalRange",
      id: "updateTime",
      filterable: false,
      width: 180,
    },
    {
      Header: "Thời gian Valid",
      accessor: "validTime",
      id: "validTime",
      filterable: false,
      width: 180,
    },
  ];

  return (
    <Modal isOpen={modalTest} style={{ maxWidth: 1000 }} >
      <ModalHeader style={{ background: configApi.USE_COLOR, color: "#fff" }}>
        KẾT QUẢ XÉT NGHIỆM
      </ModalHeader>
      <AvForm onValidSubmit>
        <ModalBody>
          <FormGroup>
            <Row>
              <Col>
                <Card>
                <Row style={{paddingLeft:26}}>
                      <Col lg={12} className="pl-0">
                        <FormGroup row>
                          <Col lg={6}>
                            Họ và tên:{" "}
                            <strong>{dataTest!=null && dataTest.length>0?dataTest[0].PatientName:""}</strong>
                          </Col>
                          <Col lg={2}>
                            Giới tính: <strong>{dataTest!=null && dataTest.length>0?dataTest[0].Sex:""}</strong>
                          </Col>
                          <Col lg={4}>
                            Ngày sinh:{" "}
                            <strong>{dataTest!=null && dataTest.length>0?dataTest[0].DateOfBirth:""}</strong>
                          </Col>
                        </FormGroup>
                      </Col>
                      <Col lg={12} className="pl-0">
                        <FormGroup row>
                          <Col lg={6}>
                            Địa chỉ: <strong>{dataTest!=null && dataTest.length>0?dataTest[0].Address:""}</strong>
                          </Col>
                          <Col lg={6}>
                            Chẩn đoán:{" "}
                            <strong>{dataTest!=null && dataTest.length>0?dataTest[0].Description:""}</strong>
                          </Col>
                        </FormGroup>
                      </Col>
                    </Row>
                  <ListGroup className="todo-list-wrapper" flush>
                    {dataTest.map((itemsign) => {
                      return (
                        <ListGroupItem>
                          <div className="widget-content p-0">
                            {itemsign.listSubTestResult.length > 0 ? (
                              <div>
                                <div
                                  className="widget-heading"
                                  style={{ paddingBottom: 5 }}
                                >
                                  <b className="text-primary">
                                    {itemsign.testname}
                                  </b>
                                </div>
                                {itemsign.listSubTestResult.map(
                                  (itemdetail) => {
                                    return (
                                      <div>
                                        <Row>
                                          <Col
                                            lg={12}
                                            style={{
                                              borderStyle: "ridge",
                                              borderWidth: 1,
                                            }}
                                          >
                                            <FormGroup row>
                                              <Col lg={5} sm={12} xs={12}>
                                                {itemdetail.testname}
                                              </Col>
                                              <Col lg={3} sm={6} xs={4}>
                                                Kết quả:{" "}
                                                <span
                                                  style={{
                                                    fontWeight: "bold",
                                                    color:
                                                      itemdetail.color == null
                                                        ? ""
                                                        : ColorTest[
                                                            itemdetail.color
                                                          ],
                                                  }}
                                                >
                                                  {itemdetail.result}
                                                </span>
                                              </Col>
                                              <Col lg={4} sm={6} xs={8}>
                                                Giá trị tham khảo:{" "}
                                                {itemdetail.normalRange}
                                              </Col>
                                            </FormGroup>
                                          </Col>
                                        </Row>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            ) : (
                              <div>
                                <Row>
                                  <Col
                                    lg={12}
                                    style={{
                                      borderStyle: "ridge",
                                      borderWidth: 1,
                                    }}
                                  >
                                    <FormGroup row>
                                      <Col lg={5} sm={12} xs={12}>
                                        <b className="text-primary">
                                          {" "}
                                          {itemsign.testname}
                                        </b>
                                      </Col>
                                      <Col lg={3} sm={6} xs={4}>
                                        Kết quả:{" "}
                                        <span
                                          style={{
                                            fontWeight: "bold",
                                            color:
                                              itemsign.color == null
                                                ? ""
                                                : ColorTest[itemsign.color],
                                          }}
                                        >
                                          {itemsign.result}
                                        </span>
                                      </Col>
                                      <Col lg={4} sm={6} xs={8}>
                                        Giá trị tham khảo:{" "}
                                        {itemsign.normalRange}
                                      </Col>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </div>
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Row
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              color="danger"
              className="ml-2"
              onClick={() => {
                setModalTest(false);
              }}
            >
              <span className="mr-2 lnr lnr-exit"></span>Đóng
            </Button>
          </Row>
        </ModalFooter>
      </AvForm>
    </Modal>
  );
}
