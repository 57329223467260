import React, { Component, Fragment } from "react";
import { withRouter ,Link, Route } from 'react-router-dom'
import { connect } from "react-redux";
import MetisMenu from "react-metismenu";
import RouterLink from './subclass';
import history from '../../stores/history';
import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import {
  MainNav,
  ComponentsNav,
  FormsNav,
  WidgetsNav,
  ChartsNav,
} from "./NavItems";

class Nav extends Component {
  state = {};
 
  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props;
    setEnableMobileMenu(!enableMobileMenu);
  };

  render() {
    return (
      <Fragment>
        <h5 className="app-sidebar__heading">Menu</h5>
        <MetisMenu history={history} content={MainNav} onSelected={this.toggleMobileSidebar} LinkComponent={RouterLink}
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

        {/* <h5 className="app-sidebar__heading">UI Components</h5>
        <MetisMenu history={history} content={ComponentsNav} onSelected={this.toggleMobileSidebar} LinkComponent={RouterLink}
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

        <h5 className="app-sidebar__heading">Dashboard Widgets</h5>
        <MetisMenu history={history}  content={WidgetsNav} onSelected={this.toggleMobileSidebar}  LinkComponent={RouterLink}
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

        <h5 className="app-sidebar__heading">Forms</h5>
        <MetisMenu history={history} content={FormsNav} onSelected={this.toggleMobileSidebar} LinkComponent={RouterLink}
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/>

        <h5 className="app-sidebar__heading">Charts</h5>
        <MetisMenu history={history} content={ChartsNav} onSelected={this.toggleMobileSidebar} LinkComponent={RouterLink}
          className="vertical-nav-menu" iconNamePrefix="" classNameStateIcon="pe-7s-angle-down"/> */}
      </Fragment>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
}
const mapStateToProps = (state) => ({
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
