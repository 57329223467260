import React, { Fragment, useEffect, useState } from "react";
import * as configApi from '../../config/configApi.js';
import Slider from "react-slick";
import SimpleReactValidator from 'simple-react-validator';
import bg1 from "../../assets/utils/images/originals/city.jpg";
import bg2 from "../../assets/utils/images/originals/citydark.jpg";
import bg3 from "../../assets/utils/images/originals/citynights.jpg";
import { connect } from 'react-redux';
import { Col, Row, Button, Form, FormGroup, Label, Input } from "reactstrap";
import history from '../../stores/history';
import { store } from '../../stores';
import ApiCaller from "../../lib/ApiCaller";
import {
    ToastContainer,
    toast
} from "react-toastify";
import  "./index.css";

const LoginValidator = new SimpleReactValidator();
function handleSubmit(username, password, props) {
    const notify = (msg) => toast(msg, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });;
    if (LoginValidator.allValid()) {
        ApiCaller.post("/LoginViewResult/SignInWebResult", { UserName: username, Password: password })
            .then(res => {

                if (res.data.isSuccess) {
                    let token = '';
                    let name = '';
                    let userID = '';
                    let groupid = '';
                    let title = '';
                    if (res.data.token) {
                        token = res.data.token
                        name = res.data.name || ''
                        userID = res.data.userID || ''
                        title = res.data.title || ''
                    }
                    localStorage.setItem('token', token);
                    localStorage.setItem('name', name);
                    localStorage.setItem('userID', userID);
                    store.dispatch({
                        type: 'USER_SET_DATA', 
                        data: {
                            token: res.data.token, 
                            name: name, 
                            userID: userID,
                        }
                    });
                    const from = '';
                    if (props.location.state) {
                        from = props.location.state.from;
                    }
                    if (from !== '' && from !== undefined) {
                        history.push(from);
                    } else {
                        history.push('/');
                    }
                } else {
                    notify(
                        res.data.err.message
                    );
                }
            })
    } else {
        LoginValidator.showMessages();

        // rerender to show messages for the first time
        // you can use the autoForceUpdate option to do this automatically`
        //  this.forceUpdate();
    }
}
const useForceUpdate = () => {
    const [count, setCount] = useState(0)

    const increment = () => setCount(prevCount => prevCount + 1)
    return [increment, count]
}

function checkprops(props) {

    if (props === undefined || props.global === undefined || props.global.token === undefined) return
    if (props && props.global.token !== '') {
        if (props.location && props.location.state) {
            const { from } = props.location.state;
            if (from !== '' && from !== undefined) {
                history.push(from);
            } else {
                history.push('/');
            }
        } else {
            history.push('/');
        }
    }
}

function RenderPageLogin(props) {
    const primaryColor = configApi.USE_COLOR;
    const loginNote = configApi.LOGIN_NOTE;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [forceUpdate] = useForceUpdate()
    const onClickHandler = e => {
        forceUpdate()
    }
    useEffect(() => {
        checkprops(props);
    }, []);
    let settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        fade: true,
        initialSlide: 0,
        autoplay: true,
        adaptiveHeight: true,
    };
    return (
        <Fragment>
            <ToastContainer></ToastContainer>
            <div className="h-100">
                <Row className="h-100 no-gutters">
                    <Col lg="3" className="d-none d-lg-block">
                    </Col>
                    <Col lg="6" md="12" className="h-100 d-flex bg-white justify-content-center align-items-center" id="loginNotBG">
                        <Col lg="6" md="10" sm="12" className="mx-auto app-login-box">
                            {/* <div className="app-logo" /> */}
                            <h4 className="mb-0">
                                <div style={{ color: primaryColor, opacity: "1" }}  className='HeaderTitle'>TRA CỨU KẾT QUẢ</div>
                                <span>Vui lòng đăng nhập tài khoản của bạn.</span>
                            </h4>
                            <Row className="divider" />
                            <div>
                                <Form>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                               
                                                <Input onChange={(e) => { setUsername(e.target.value) }} type="Username" name="Username" id="Username" placeholder="Tài khoản: Nhập tài khoản tại đây..." />
                                                {LoginValidator.message('Username', username, 'required')}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row form>
                                        <Col md={12}>
                                            <FormGroup>
                                               
                                                <Input onChange={(e) => { setPassword(e.target.value) }} type="password" name="password" id="examplePassword" placeholder="Mật khẩu: Nhập mật khẩu tại đây..." />
                                                {LoginValidator.message('password', password, 'required')}
                                            </FormGroup>
                                        </Col>
                                    </Row>

                                    {
                                        loginNote && <div className="my-3" style={{...(loginNote?.style || {color: '#dc2626'})}}>
                                            * {loginNote?.title}
                                        </div>
                                    }
                               
                                    <FormGroup>
                                    <Button className="hover" style={{width:100+'%', backgroundColor: primaryColor, border: 'none'}} size="lg" onClick={() => {
                                                handleSubmit(username, password, props)
                                                onClickHandler()
                                            }}>
                                                Đăng nhập
                                            </Button>
                                    </FormGroup>
                                    <Row className="divider" />
                                 
                                </Form>
                            </div>
                        </Col>
                    </Col>
                </Row>
            </div>
        </Fragment>
    );
}


function mapStateToProps(state, props) {
    return {
        global: state.global
    };
}



export default connect(mapStateToProps)(RenderPageLogin)