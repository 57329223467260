import React, { Fragment } from "react";
import cx from "classnames";

import { connect } from "react-redux";

import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

import HeaderLogo from "../AppLogo";

import SearchBox from "./Components/SearchBox";
import MegaMenu from "./Components/MegaMenu";
import UserBox from "./Components/UserBox";
import HeaderRightDrawer from "./Components/HeaderRightDrawer";

import HeaderDots from "./Components/HeaderDots";

import {
  Row,
  Col,

} from "reactstrap";

const urlLogo = process.env.PUBLIC_URL + "/images/banner-removebg.png";
class Header extends React.Component {
  render() {
    let {
      headerBackgroundColor,
      enableMobileMenuSmall,
      enableHeaderShadow,
    } = this.props;
    return (
      <Fragment>
        {/* style={{ backgroundColor: "white" }} */}
        <div className="container-fluid"  id="header_Big">
          <Row style={{ paddingTop: "15px" }}>
            <Col className="col-sm-12 col-md-1 col-lg-2" >
            </Col>
            <Col className="col-sm-12 col-md-8 col-lg-8" style={{ textAlign: "center" }}>

              {/* <h4>
                <a href="/">
                  <img className="imgLogo"
                    src={process.env.PUBLIC_URL + "/images/logo_hp.png"}></img>
                  <span className="bvname">BỆNH VIỆN QUỐC TẾ HẢI PHÒNG</span>
                </a>
              </h4> */}

            </Col>
            <Col className="col-sm-12 col-md-3 col-lg-2"><UserBox /></Col>
          </Row>
        </div>
        {/* style={{ backgroundColor: "white" }}  */}
        <div className="container-fluid"id="header_Small">
          <Row style={{ paddingTop: "15px" }}>
            <Col className="col-sm-12" style={{ textAlign: "center", width: "100%" }}>

              {/* <h4>
                <a href="/">
                  <img className="imgLogo_Small"
                    src={process.env.PUBLIC_URL + "/images/logo_hp.png"}>
                  </img>
                  <span className="bvname_Small">BỆNH VIỆN QUỐC TẾ HẢI PHÒNG</span>
                </a>
              </h4> */}

            </Col>

          </Row>
          <Row>

            <Col className="col-sm-12"><UserBox className="float-right" /></Col>
          </Row>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  enableHeaderShadow: state.ThemeOptions.enableHeaderShadow,
  closedSmallerSidebar: state.ThemeOptions.closedSmallerSidebar,
  headerBackgroundColor: state.ThemeOptions.headerBackgroundColor,
  enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Header);


