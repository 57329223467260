import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import 'react-toastify/dist/ReactToastify.css'
import * as serviceWorker from "./serviceWorker";
import { Route, Switch } from 'react-router';
import history from './stores/history';
import { ConnectedRouter } from 'connected-react-router';
import "./assets/base.scss";
import Main from "./DemoPages/Main";
import configureStore from "./config/configureStore";
import Login from './Components/login';
import UnknowPage from './Components/unknowPage';
import Authenticated from './Components/authenticated';
import { Provider } from "react-redux";
import { store } from './stores';
//Custom style react table
import './app.css';
import QuickView from "../src/Views/QuickView";

// const store = configureStore();
const rootElement = document.getElementById("root");

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
       <ConnectedRouter history={history}>
        <Switch>
          <Route path='/login' component={Login} />
          <Route path='/v' component={QuickView} />
          <Authenticated exact component={Component} /> 
          <Route component={UnknowPage} />
        </Switch>
      </ConnectedRouter>
    </Provider>,
    rootElement
  );
};

renderApp(Main);

if (module.hot) {
  module.hot.accept("./DemoPages/Main", () => {
    const NextApp = require("./DemoPages/Main").default;
    renderApp(NextApp);
  });
}
serviceWorker.unregister();
