import React, { useState, useEffect, Fragment } from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import history from "../../stores/history";
import { store } from "../../stores";
// Layout
import AppHeader from "../../Layout/AppHeader/";
import AppSidebar from "../../Layout/AppSidebar/";
import AppFooter from "../../Layout/AppFooter/";

// Theme Options
import ThemeOptions from "../../Layout/ThemeOptions/";
import { Row, Col } from "reactstrap";
import SidList from "../../../src/Views/Sid/SidList";
import SidService from "../../../src/Views/Sid/SidService";
import * as configApi from '../../config/configApi.js';
function Checking(props) {
  const [filter, setFilter] = useState({ pid: "", dateIn: new Date() });
  const [data, setData] = useState({});
  const [err, setErr] = useState("");

  useEffect(() => {
    try {
      var url_string = window.location.href;
      var arr = url_string.split("/");
      var token = arr[arr.length - 1];
      //xử lý 1 số trường hợp nhảy từ link zalo,... tự truyền thêm parameter
      if (token) {
        arr = token.split("?");
        token = arr[0];
      }
      if (!token) {
        history.push("");
      }
      SidService.GetToken(token).then((res) => {
        if (res.data.isSuccess) {
          setFilter({
            pid: res.data.item.userID,
            dateIn: res.data.item.dateIn,
          });
          localStorage.setItem("token", res.data.item.tokenID);
          localStorage.setItem("name", res.data.item.userID);
          localStorage.setItem("userID", res.data.item.userID);
          store.dispatch({
            type: "USER_SET_DATA",
            data: {
              token: res.data.item.tokenID,
              name: res.data.item.userID,
              userID: res.data.item.userID
            },
          });
        } else {
          setErr(res.data.err.msgString);
        }
      });
    } catch (error) {
      history.push("");
    }
  }, []);

  return (
    <Fragment>
      <div id="div-all">
        <div className="app-container app-theme-white fixed-header fixed-sidebar fixed-footer body-tabs-shadow-btn">
          <AppHeader />
          {!err && (
            <div
              style={{
                marginTop: 168,
                borderStyle: "dashed",
                borderColor: configApi.USE_COLOR,
              }}
            >
              <div
                style={{
                  backgroundColor: configApi.USE_COLOR,
                  fontSize: "large",
                  padding: 5,
                }}
              >
                <h4
                  className="text-center"
                  style={{ color: "white", fontSize: "1.4em" }}
                >
                  DANH SÁCH KẾT QUẢ XÉT NGHIỆM
                </h4>
              </div>
              <div>
                <SidList pid={filter.pid} dateIn={filter.dateIn}></SidList>
              </div>
            </div>
          )}

          {err && (
            <div>
              <Row style={{ width: "100%", paddingLeft: "20px" }}>
                <Col className="col-sm-12 mt-5">
                  <h5 style={{ textAlign: "center", color: "red" }}>{err}</h5>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}

//export default Checking;

const mapStateToProps = (state) => ({ global: state.global });
export default connect(mapStateToProps)(Checking);
