import CSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Label,
  ListGroup,
  ListGroupItem,
  FormGroup,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap";
import { Document, Page, pdfjs } from "react-pdf";
import { MyModal } from "../../../src/MyForders/Components";
import DateTimeHelper from "../../../src/assets/utils/helpers/DateTimeHelper";

import SidService from "../../../src/Views/Sid/SidService";
import "./Sid.css";
import ResultModal from "./ResultModal";
import ApiCaller from "../../lib/ApiCaller";
import * as configApi from "../../config/configApi.js";
function Checking(props) {
  //Load thư viện PDF
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  //---------------------------------------------------
  const [listData, setListData] = useState([]);
  // const [filter, setFilter] = useState({ pid: ""});
  const [isShowModal, setIsShowModal] = useState(false);
  const [width, setWidth] = useState(0);
  console.log("width=", width);
  const [pdfData, setPdfData] = useState(null);
  const [Patient, SetPatient] = useState(null);
  const [pdfDataFile, setPdfDataFile] = useState(null);
  const [sid, setSid] = useState();
  const [siteCode, setSiteCode] = useState();
  const [scale, setScale] = useState(1);
  //pdf page
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [modalTest, setModalTest] = useState(false);
  const [dataTest, setDataTest] = useState([]);
  const [listPage, setListPage] = useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }
  const primaryColor = configApi.USE_COLOR;

  useEffect(() => {
    getList();
    if (window.innerWidth > 450) {
      setScale(1);
    } else {
      setScale(6);
    }

    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getList();
  }, [props.dateIn]);

  const getList = () => {
    const filter = {
      pid: props.pid,
      dateIn: props.dateIn,
    };
    if (!filter.pid) {
      return;
    }
    SidService.GetListSid(filter).then((res) => {
      if (res.data.isSuccess) {
        setListData(res.data.data);
      }
    });
  };
  function onDocumentLoadSuccess({ numPages }) {
    var _numPages = [];
    for (var i = 0; i < numPages; i++) {
      _numPages.push(i + 1);
    }
    setListPage(_numPages);
    setNumPages(numPages);
  }
  //Tải file pdf
  const DownloadListSignaturePdf = (item) => {
    const URI =
      "/Signature/DownloadListSignaturePdf?Sitecode=" +
      item.siteCode +
      "&SID=" +
      item.sid +
      "&ID=" +
      item.rowguid;
    window.location.href = configApi.URL_API + URI;
  };
  //Xem file PDF
  const ViewResultPDF = (data) => {
    setPdfData("");
    SidService.GetFileResultBase64Info(data.sid, data.siteCode).then((res) => {
      if (res.data.isSuccess) {
        const blob = base64toBlob(
          "data:application/pdf;base64," + res.data.item.pdfBase64
        );
        const url = URL.createObjectURL(blob);
        setPdfData(url);
        toggle();
      }
    });
  };
  const toggle = () => {
    setIsShowModal(!isShowModal);
  };
  const base64toBlob = (data) => {
    const base64WithoutPrefix = data.substr(
      "data:application/pdf;base64,".length
    );
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: "application/pdf" });
  };
  const GetResult = (sid, sitecode) => {
    ApiCaller.get(
      `/PatientCenter/GetPatientInfo?sid=${sid}&sitecode=${sitecode}`
    ).then((res) => {
      if (res.data.isSuccess) {
        let item = res.data.data[0];
        console.log("PATIENT", item);
        let data = [];
        let stt = 0;
        for (let rec of item.listTestResult) {
          if (rec.listSubTestResult && rec.listSubTestResult.length > 0) {
            stt += 1;
            rec.stt = stt;
            rec.isParrent = true;
            rec.PatientName = item.patientName;
            rec.DateOfBirth = item.dateOfBirth;
            rec.Sex = item.sex == "M" ? "Nam" : item.sex == "F" ? "Nữ" : "?";
            rec.Address = item.address;
            rec.Description = item.description;
            data.push(rec);
            // data = data.concat(rec.listSubTestResult);
          } else {
            stt += 1;
            rec.stt = stt;
            rec.isParrent = true;
            rec.PatientName = item.patientName;
            rec.DateOfBirth = item.dateOfBirth;
            rec.Sex = item.sex == "M" ? "Nam" : item.sex == "F" ? "Nữ" : "?";
            rec.Address = item.address;
            rec.Description = item.description;
            data.push(rec);
          }
        }
        console.log("Select data:", data);
        setDataTest(data);
      }
    });
  };

  const refA = useRef(null);
  return (
    <React.Fragment>
      <CSSTransitionGroup
        component="div"
        transitionName="TabsAnimation"
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
      >
        <Row style={{marginRight: 0, marginLeft: 0}}>
          <Col sm="12" style={{padding: 0}}>
            <ListGroup className="todo-list-wrapper" flush>
              {listData.map((itemsign) => {
                return (
                  <ListGroupItem>
                    <div className="todo-indicator" style={{backgroundColor: primaryColor, opacity: 1}} />
                    <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                        <Row className="w-100">
                          <Col sm={12} md={12} lg={8}>
                            <div className="widget-content-left d-flex align-items-center" style={{height: '100%'}}>
                              <div
                                className="widget-heading"
                                style={{ opacity: 1, color: primaryColor }}
                              >
                                <span style={{ color: "black" }}>SID:</span>{" "}
                                <b>{itemsign.sid}</b> 
                                <span style={{ color: "black",paddingLeft:9  }}>
                                    HỌ TÊN:
                                  </span>{" "}
                                <b>{itemsign.patientName}</b>
                                <span style={{ color: "black",paddingLeft:9 }}>
                                  NGÀY KHÁM:
                                </span>{" "}
                                <b>{DateTimeHelper.getDateString(itemsign.dateIn)}</b>
                              </div>
                            </div>
                          </Col>

                          <Col sm={12} md={12} lg={4}>
                            <div
                              className={`w-100 d-flex ${
                                width > 991 || !width
                                  ? "justify-content-end"
                                  : "justify-content-start mt-3"
                              }`}
                            >
                              <button
                                className="mb-1 mr-2 btn-shadow btn"
                                style={{
                                  backgroundColor: primaryColor,
                                  color: "#FFF",
                                }}
                                onClick={() => ViewResultPDF(itemsign)}
                              >
                                <div className="d-flex align-items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.3em"
                                    height="1.3em"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
                                    />
                                  </svg>
                                  <div className="ml-1">XEM KẾT QUẢ</div>
                                </div>
                              </button>
                              <button
                                className="mb-1 mr-2 btn-shadow btn"
                                style={{
                                  backgroundColor: primaryColor,
                                  color: "#FFF",
                                }}
                                onClick={() =>
                                  DownloadListSignaturePdf(itemsign)
                                }
                              >
                                <div className="d-flex align-items-center">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="1.3em"
                                    height="1.3em"
                                    viewBox="0 0 24 24"
                                  >
                                    <g
                                      stroke="currentColor"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                    >
                                      <path
                                        fill="none"
                                        stroke-dasharray="14"
                                        stroke-dashoffset="14"
                                        d="M6 19h12"
                                      >
                                        <animate
                                          fill="freeze"
                                          attributeName="stroke-dashoffset"
                                          dur="0.4s"
                                          values="14;0"
                                        />
                                      </path>
                                      <path
                                        fill="currentColor"
                                        d="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5"
                                      >
                                        <animate
                                          attributeName="d"
                                          calcMode="linear"
                                          dur="1.5s"
                                          keyTimes="0;0.7;1"
                                          repeatCount="indefinite"
                                          values="M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5;M12 4 h2 v3 h2.5 L12 11.5M12 4 h-2 v3 h-2.5 L12 11.5;M12 4 h2 v6 h2.5 L12 14.5M12 4 h-2 v6 h-2.5 L12 14.5"
                                        />
                                      </path>
                                    </g>
                                  </svg>
                                  <div className="ml-1">TẢI FILE KẾT QUẢ</div>
                                </div>
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </ListGroupItem>
                );
              })}
            </ListGroup>
            {ResultModal(modalTest, setModalTest, dataTest)}
          </Col>
        </Row>
        <MyModal
          style={{ width: "100%" }}
          className="modal-fullscreen"
          isOpen={isShowModal}
          unmountOnClose={true}
          toggle={toggle}
        >
          <ModalHeader
            toggle={toggle}
            className="center"
            style={{ background: configApi.USE_COLOR, color: "#fff" }}
          >
            <h5 style={{ width: "100%", textAlign: "center" }}>
              KẾT QUẢ XÉT NGHIỆM
            </h5>
          </ModalHeader>
          <ModalBody style={{ overflowY: "auto" }}>
            <div>
              <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
                {listPage != undefined && listPage.length > 0 ? (
                  listPage.map((pageNumber) => (
                    <Page
                      pageNumber={pageNumber}
                      scale={window.innerWidth < 768 ? 0.7 : 1.5}
                      printOut={true}
                    />
                  ))
                ) : (
                  <div></div>
                )}
              </Document>
            </div>
          </ModalBody>
        </MyModal>
      </CSSTransitionGroup>
    </React.Fragment>
  );
}
export default Checking;
