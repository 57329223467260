import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { store } from '../../stores';

import ApiCaller from "../../lib/ApiCaller";
import { useHistory } from 'react-router-dom'
function RenderAuth(props) {
  const history = useHistory()
  useEffect(() => {
    if (props.global.token === '' || props.global.token === undefined) {
      history.push('/login');
    }
  }, [props.global.token]);
  useEffect(() => {
    ApiCaller.get("Auth/me", null, { headers: {
      Authorization: `Bearer ${props.global.token}`
    }}, false)
      .then(res => { }

      ).catch((ex) => {
        if (ex.toString().includes("Request failed with status code 401")) {
          localStorage.setItem('token', '');
          localStorage.setItem('name', '');
          localStorage.setItem('groupid', '');
          localStorage.setItem('title', '');
          localStorage.setItem('userID', '');
          store.dispatch({ type: 'USER_SET_DATA', data: { token: '', name: '' } });
        }
      })

  }, [history]);

  var Comp = props.component;
  let token = localStorage.getItem('token') || '';
  if (token !== '') {
    return (
      <React.Fragment>
        <Comp />
      </React.Fragment>);
  } else {

    return (
      <React.Fragment>

      </React.Fragment>);

  }
}




//   export default connect(
//     (state: ApplicationState["global"]) => state, // Selects which state properties are merged into the component's props
//     Global.actions // Selects which action creators are merged into the component's props
// )(Authenticated);
const mapStateToProps = (state) => ({
  global: state.global,
});

export default connect(
  mapStateToProps
)(RenderAuth);