import React from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';

const defaultFormat = "dd/MM/yyyy";

function MyDatePicker(props) {

    const myProps = { ...props };
    if (myProps.selected !== undefined && myProps.selected !== null) {
        myProps.selected = moment(myProps.selected).toDate();
    }

    return (
        <DatePicker
            wrapperClassName=""
            className="form-control"
            placeholderText={defaultFormat}
            dateFormat={defaultFormat}
            {...myProps}
        />
    )
}
export default MyDatePicker