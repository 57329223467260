import ApiCaller from "../../../src/lib/ApiCaller";
const prefixApi = "/ViewResult";

const PooledSampleService = {
    GetListSid: (data) => {
        return new Promise((resolve, reject) => {
            return ApiCaller.post(prefixApi + `/GetListSid`, data).then((response) => {
                return resolve(response)
            }).catch()
        });
    },
    GetToken: (token) => {
        return new Promise((resolve, reject) => {
            return ApiCaller.get(`/LoginViewResult/GetToken?token=${token}`).then((response) => {
                return resolve(response)
            }).catch((e) => console.log(e))
        });
    },
    GetFileResultBase64Info :(sid, siteCode) => {
        return new Promise((resolve, reject) => {
          return ApiCaller.get(
            `/ViewResult/GetFileResultBase64Info?sid=${sid}&siteCode=${siteCode}`
          )
            .then((response) => {
              return resolve(response);
            })
            .catch();
        });
      }
}
export default PooledSampleService;