import axios from 'axios';
import * as configApi from '../../config/configApi.js';
import {
    ToastContainer,
    toast
} from "react-toastify";
import { connect } from 'react-redux';
//Config common
const apiDefault = axios.create({
    baseURL: configApi.URL_API,
    timeout: 120000
});

const methodConst = {
    get: "get",
    post: "post",
    put: "put",
    delete: "delete",
}


class ApiCaller {
    static state = ApiCaller.getDefaultState();

    static getDefaultState() {
        return {
            ...ToastContainer.defaultProps,
            transition: "bounce",
            type: "error",
            disableAutoClose: false,
        };
    }

    static async get(url, params = null, customConfig = null, isNotify = true) {
        return ApiCaller.callApi(url, methodConst.get, null, params, customConfig, isNotify)
    }
    static async post(url, data, customConfig = null) {
        return await ApiCaller.callApi(url, methodConst.post, data, null, customConfig);
    }
    static async put(url, data, customConfig = null) {
        return await ApiCaller.callApi(url, methodConst.put, data, null, customConfig);
    }
    static async delete(url, data, customConfig = null) {
        return await ApiCaller.callApi(url, methodConst.delete, data, null, customConfig);
    }

    static async callApi(url, method, data = null, params = null, customConfig = null, isNotify = true) {

        //handle config common
        let config = {};
        if (customConfig != null && customConfig !== undefined) {
            config = customConfig;
        }
        config.url = url;
        config.method = method;

        if (params != null) {
            config.params = params;
        }
        if (method === methodConst.post || method === methodConst.put || method === methodConst.delete) {
            config.data = data;
        }
     

        //Gắn token khi gọi api
        config.headers = { 'Authorization': 'Bearer ' + global.localStorage.token }

        //Call api
        // return await apiDefault.request(config)
        //     .then(response => {

        //         if (!response.data.isSuccess) {
        //             ApiCaller.handleApiUnsuccessful(response.data.err);
        //         }
        //         return response;
        //     })
        //     .catch(error => {
        //         ApiCaller.handleErrorApi(error);
        //         return Promise.reject(error);
        //     });
        try {
            let res = await apiDefault(config);
            //console.log("ApiCaller - res", res);
            if (!res.data.isSuccess) {
                if (isNotify) {
                    ApiCaller.handleApiUnsuccessful(res.data.err);
                }
                
            }
            return res;
        } catch (error) {
            //console.log("apiDefault.headers", apiDefault.defaults);
            if (isNotify) {
                ApiCaller.handleErrorApi(error);
            }
            
            return Promise.reject(error);
        }
    }

    //function ultis
    static async handleApiUnsuccessful(error) {
        console.log("apiDefault error", error);
        try {
            // const errStr = "Mã lỗi: " + error.msgCode + "; Nội dung: " + error.msgString;
            const errStr = "Nội dung: " + error.msgString;
            toast[ApiCaller.state.type](
                errStr
            );
        }
        catch (error) {

        }
        console.log("handleApiUnsuccessful error", error);
    }
    //function ultis
    static async handleErrorApi(error) {
        let errStr = "";
        try {
            if (error.message === "Network Error") {
                errStr = "Không thể kết nối tới server"
            } else {
                errStr = error.message;
            }
        } catch (error) {
            errStr = JSON.stringify(error)
        }
        toast[ApiCaller.state.type](
            errStr
        );
        console.log("handleErrorApi error", error);
    }


}

const mapStateToProps = (state) => ({
    global: state.global,
});
export default connect(
    mapStateToProps
)(ApiCaller);