import React from 'react';
import { Modal } from "reactstrap";

function MyModal(props) {
    const myProps = { ...props };

    return (
        <Modal
        {...myProps}>
        </Modal>
    )
}
export default MyModal