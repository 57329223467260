import moment from "moment";
import configCommon from "../../../config/configCommon.json";

const DateTimeHelper = {
    getDateTimeString: function (dateInput) {
        if (!dateInput) {
            return "";
        } else {
            return moment(dateInput).format(configCommon.DATETIME_FORMAT_DISPLAY);
        }

    },
    
    getDateString: function (dateInput) {
        if (!dateInput) {
            return "";
        } else {
            return moment(dateInput).format(configCommon.DATE_FORMAT_DISPLAY);
        }

    },
}

export default DateTimeHelper;